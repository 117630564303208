/* eslint-disable react/jsx-no-undef */
import React, { useEffect } from "react";
import Heading from "../Reuseable-Components/font/Heading";
import { Link } from "react-router-dom";

export default function DeafAccounts() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  let deafAccountObj = [
    {
      link: "https://files.siscobank.com/deaf_Account.pdf",
      month: "",
    },
    {
      link: "https://files.siscobank.com/unclaimed%20deposit%20July%202024_UDRPT13173056.pdf",
      month: "July, 2024",
    },
    {
      link: "https://files.siscobank.com/Unclaimed%20Deposit%20OCT%202024_UDRPT13173056.pdf",
      month: "October, 2024",
    },
    {
      link: "https://files.siscobank.com/deaf%20nov%20UPLOAD_gangtok.pdf",
      month: "November, 2024",
    },
  ];

  return (
    <section className=" h-screen  ">
      <Heading classes={"py-10 "}>Deaf Accounts</Heading>

      {deafAccountObj?.map((deafAccObj, index) => (
        <div className="mx-10 md:mx-20 md:my-4 py-1">
          <Link to={deafAccObj?.link} target="_blank">
            <div className=" flex items-center gap-2 cursor-pointer  text-sm md:text-base">
              <span>{index + 1}.</span>
              <p className="text-[#0e381d] font-medium   ">
                Deaf Account <span>{deafAccObj?.month}</span>
              </p>

              <span className="italic text-sm text-blue-800 hover:underline  hover:underline-offset-2 ">
                (click to download)
              </span>
            </div>
          </Link>

          {/* <Link to={deafAccObj?.link} target="_blank">
            <div className="flex items-center gap-2 cursor-pointer pt-4 ">
              <span>2.</span>
              <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500   ">
                Deaf Account {deafAccObj?.month}
              </p>
              <span className="italic text-sm text-blue-800">
                (click to download)
              </span>
            </div>
          </Link> */}
        </div>
      ))}

      {/* <div className="hidden md:block md:mx-20 md:my-4">
        <Link to="https://files.siscobank.com/deaf_Account.pdf" target="_blank">
          <div className=" flex items-center gap-2 cursor-pointer ">
            <span>1.</span>
            <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500  ">
              Deaf Account
            </p>
            <span className="italic text-sm text-blue-800">
              (click to download)
            </span>
          </div>
        </Link>

        <Link
          to="https://files.siscobank.com/deaf%20nov%20UPLOAD_gangtok.pdf"
          target="_blank"
        >
          <div className="flex items-center gap-2 cursor-pointer pt-4 ">
            <span>2.</span>
            <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500   ">
              Deaf Account (November)
            </p>
            <span className="italic text-sm text-blue-800">
              (click to download)
            </span>
          </div>
        </Link>
      </div>

      <div className="block md:hidden mx-6">
        <Link to="https://files.siscobank.com/deaf_Account.pdf" target="_blank">
          <div className=" flex  gap-2 cursor-pointer items-center text-sm ">
            <span>1.</span>
            <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500  ">
              Deaf Account
            </p>
            <span className="italic text-xs text-blue-800 ">
              &nbsp;(click to download)
            </span>
          </div>
        </Link>

        <Link
          to="https://files.siscobank.com/deaf%20nov%20UPLOAD_gangtok.pdf"
          target="_blank"
        >
          <div className=" flex  gap-2 cursor-pointer pt-4 text-sm items-center">
            <span>2.</span>
            <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500  ">
              Deaf Account (November)
            </p>
            <span className="italic text-xs text-blue-800 ">
              (click to download)
            </span>
          </div>
        </Link>
      </div> */}
    </section>
  );
}
