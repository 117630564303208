import React, { useState } from "react";
import SubHeading from "../font/SubHeading";
import { Link } from "react-router-dom";

export default function CarrersCard({ cardDetails }) {
  return (
    <>
      <>
        <div className="border rounded-sm max-w-md px-6 py-8 font-sans bg-white shadow-md">
          <SubHeading className="font-bold">{cardDetails?.heading}</SubHeading>

          <div className="my-2">
            <span className="px-2 py-1 rounded-2xl text-xs bg-slate-600 text-white">
              {cardDetails?.pillTitle}
            </span>
          </div>

          <div className="border rounded-sm p-3 bg-slate-100 my-3 text-sm">
            <ul>
              <li>
                <label className="font-semibold">Post Name:</label>
                <span className="pl-1">{cardDetails?.postName}</span>
              </li>

              <li>
                <p>
                  <label className="font-semibold">Number of Vacancies: </label>
                  {cardDetails?.NumberOfVaccancies}
                </p>
              </li>

              <li>
                <p>
                  <label className="font-semibold">Apply Mode: </label>
                  {cardDetails?.ApplyMode}
                </p>
              </li>
            </ul>
          </div>
          {cardDetails?.hasMoreDocs ? (
            <>
              <Link to="/more-details">
                <p
                  className="block px-4 py-2 bg-green-700 rounded-sm text-white text-center hover:bg-green-900 text-sm mt-6 cursor-pointer"
                  // onClick={() => setShowDownloadDocsPage(true)}
                >
                  More Details
                </p>
              </Link>
            </>
          ) : (
            <>
              <a
                href="https://files.siscobank.com/SISCO%20requirements-for-engagement-of-CISO%20new.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="block px-4 py-2 bg-green-700 rounded-sm text-white text-center hover:bg-green-900 text-sm mt-6"
              >
                More Details
              </a>
            </>
          )}
        </div>
      </>
    </>
  );
}
