import React from "react";
import Heading from "../font/Heading";
import { Link } from "react-router-dom";

export default function MoreDetailsPage() {
  return (
    <>
      <div className="container mx-auto py-10 h-screen">
        <Heading classes="mb-6">More Details</Heading>

        <Link to="/careers">
          <div className="flex justify-end py-3 ">
            <span className="cursor-pointer border px-2 py-1 md:px-6 md:py-2 hover:underline text-sm md:text-base">
              back
            </span>
          </div>
        </Link>

        <div className="md:py-6 ">
          <Link
            to="https://files.siscobank.com/AdvertisementforBankingConsultant.pdf"
            target="_blank"
          >
            <p className="text-sm md:text-lg font-semibold pb-3">
              1. Employement Notice.{" "}
              <span className="text-blue-700 italic text-xs md:text-sm cursor-pointer hover:underline hover:underline-offset-2">
                (click here to download)
              </span>
            </p>
          </Link>

          <Link
            to="https://files.siscobank.com/The%20eligibility%20and%20terms%20of%20service%20.pdf"
            target="_blank"
          >
            <p className="text-sm md:text-lg font-semibold pb-3">
              2. The eligibility and terms of service.{" "}
              <span className="text-blue-700 italic  text-xs md:text-sm cursor-pointer hover:underline hover:underline-offset-2">
                (click here to download)
              </span>
            </p>
          </Link>

          <Link
            to="https://files.siscobank.com/Format%20of%20Application.pdf"
            target="_blank"
          >
            <p className="text-sm md:text-lg font-semibold pb-3">
              3. Format of Application.{" "}
              <span className="text-blue-700 italic text-xs md:text-sm cursor-pointer hover:underline hover:underline-offset-2">
                (click here to download)
              </span>
            </p>
          </Link>
        </div>
      </div>
    </>
  );
}
