/* eslint-disable react/jsx-pascal-case */
import React from "react";
import ContactUs from "../../Components/ContactUs/ContactUs";
import Products from "../../Components/ProductsCard/Products";
import PublicCarousel from "../../Components/PublicCarousel/PublicCarousel";
import RequestForService from "../../Components/RequestForService/RequestForService";
import NoticeCircularsButton from "../../Reuseable-Components/Notice&CircularsButton/NoticeCircularsButton";
import PublicCarousel_v2 from "../../Components/public_carousel_v2/Public_Carousel_v2";

import BannerDesktop3 from "../../Assets/AwardDesktop.png";
import BannerTab3 from "../../Assets/AwardTab.png";
import BannerMobile3 from "../../Assets/AwardMobile.png";

export default function Home({
  showHomeCarousel,
  setShowHomeCarousel,
  videoModalIsOpen,
  setVideoModalIsOpen,
}) {
  return (
    <>
      <NoticeCircularsButton
        setVideoModalIsOpen={setVideoModalIsOpen}
        setShowHomeCarousel={setShowHomeCarousel}
        videoModalIsOpen={videoModalIsOpen}
      />
      {/* <PublicCarousel /> */}

      {/* <div className="block lg:hidden">
        {showHomeCarousel ? (
          <div>
            <PublicCarousel_v2 />
          </div>
        ) : null}
      </div> */}

      {/* <div className="hidden lg:block">
        <PublicCarousel_v2 />
      </div> */}

      <div className="hidden md:hidden lg:block w-[100vw]">
        <img className="w-full" src={BannerDesktop3} alt="" srcset="" />
      </div>
      <div className="hidden md:block lg:hidden">
        <img src={BannerTab3} alt="" srcset="" />
      </div>
      <div className="block md:hidden lg:hidden">
        <img src={BannerMobile3} alt="" srcset="" />
      </div>

      <RequestForService />
      <Products />
      {/* <FB_Carousel /> */}
      {/* <Blogs /> */}
      <ContactUs />
    </>
  );
}
