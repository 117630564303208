import React, { useState } from "react";

export default function CheckRatesDeposit() {
  const [activeTab, setActiveTab] = useState("Term Deposit");

  // Table data for each tab
  const tables = {
    "Term Deposit": (
      <table className="border-collapse border border-gray-300 w-full lg:w-[65vw] text-left">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Deposit Period
            </th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Interest Rate (% p.a) Without Premature Closure Facility
            </th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Interest Rate (% p.a) With Premature Closure Facility
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              7 days - 14 days
            </td>
            <td className="border border-gray-300 px-4 py-2">3.25%</td>
            <td className="border border-gray-300 px-4 py-2">3.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              15 days - 29 days
            </td>
            <td className="border border-gray-300 px-4 py-2">4.00%</td>
            <td className="border border-gray-300 px-4 py-2">3.80%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              30 days - 45 days
            </td>
            <td className="border border-gray-300 px-4 py-2">5.50%</td>
            <td className="border border-gray-300 px-4 py-2">4.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              46 days - 60 days
            </td>
            <td className="border border-gray-300 px-4 py-2">6.00%</td>
            <td className="border border-gray-300 px-4 py-2">5.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              61 days - 90 days
            </td>
            <td className="border border-gray-300 px-4 py-2">6.25%</td>
            <td className="border border-gray-300 px-4 py-2">5.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              90 days - 179 days
            </td>
            <td className="border border-gray-300 px-4 py-2">6.85%</td>
            <td className="border border-gray-300 px-4 py-2">5.55%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              180 days - 270 days
            </td>
            <td className="border border-gray-300 px-4 py-2">6.85%</td>
            <td className="border border-gray-300 px-4 py-2">6.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              271 days to less than 1 year
            </td>
            <td className="border border-gray-300 px-4 py-2">7.15%</td>
            <td className="border border-gray-300 px-4 py-2">6.50%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">1 year</td>
            <td className="border border-gray-300 px-4 py-2">7.35%</td>
            <td className="border border-gray-300 px-4 py-2">7.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              1 year to less than 15 months
            </td>
            <td className="border border-gray-300 px-4 py-2">7.35%</td>
            <td className="border border-gray-300 px-4 py-2">7.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              15 months to less than 2 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.35%</td>
            <td className="border border-gray-300 px-4 py-2">7.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              2 years to less than 3 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.30%</td>
            <td className="border border-gray-300 px-4 py-2">7.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              3 years to less than 10 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.00%</td>
            <td className="border border-gray-300 px-4 py-2">6.80%</td>
          </tr>
        </tbody>
      </table>
    ),
    "More than 2 Crore": (
      <table className="border-collapse border border-gray-300  w-full lg:w-[65vw] text-left">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Deposit Period
            </th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Bulk Interest Rate (% p.a)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              7 days - 14 days
            </td>
            <td className="border border-gray-300 px-4 py-2">3.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              15 days - 29 days
            </td>
            <td className="border border-gray-300 px-4 py-2">4.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              30 days - 45 days
            </td>
            <td className="border border-gray-300 px-4 py-2">5.50%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              46 days - 60 days
            </td>
            <td className="border border-gray-300 px-4 py-2">6.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              61 days - 90 days
            </td>
            <td className="border border-gray-300 px-4 py-2">6.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              90 days - 179 days
            </td>
            <td className="border border-gray-300 px-4 py-2">6.85%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              180 days - 270 days
            </td>
            <td className="border border-gray-300 px-4 py-2">7.10%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              271 days to less than 1 year
            </td>
            <td className="border border-gray-300 px-4 py-2">7.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              1 year to less than 2 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.40%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              2 year to less than 3 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.30%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              3 year to less than 10 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.00%</td>
          </tr>
        </tbody>
      </table>
    ),
    "Less than 2 Crore": (
      <table className="border-collapse border border-gray-300  w-full lg:w-[65vw] text-left">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Deposit Period
            </th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Bulk Interest Rate (% p.a)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              7 days - 14 days
            </td>
            <td className="border border-gray-300 px-4 py-2">3.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              15 days - 29 days
            </td>
            <td className="border border-gray-300 px-4 py-2">3.80%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              30 days - 45 days
            </td>
            <td className="border border-gray-300 px-4 py-2">4.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              46 days - 60 days
            </td>
            <td className="border border-gray-300 px-4 py-2">4.75%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              61 days - 90 days
            </td>
            <td className="border border-gray-300 px-4 py-2">5.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              90 days - 179 days
            </td>
            <td className="border border-gray-300 px-4 py-2">5.30%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              180 days - 270 days
            </td>
            <td className="border border-gray-300 px-4 py-2">6.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              271 days to less than 1 year
            </td>
            <td className="border border-gray-300 px-4 py-2">6.35%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              1 year to less than 2 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              2 year to less than 3 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.25%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              3 year to less than 10 years
            </td>
            <td className="border border-gray-300 px-4 py-2">7.00%</td>
          </tr>
        </tbody>
      </table>
    ),
    // "Rate of Interest": (
    //   <table className="border-collapse border border-gray-300 w-full text-left">
    //     <thead>
    //       <tr>
    //         <th className="border border-gray-300 px-4 py-2 bg-gray-200">
    //           Tenure
    //         </th>
    //         <th className="border border-gray-300 px-4 py-2 bg-gray-200">
    //           Interest Rate (% p.a)
    //         </th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       <tr>
    //         <td className="border border-gray-300 px-4 py-2">1 year</td>
    //         <td className="border border-gray-300 px-4 py-2">6.75%</td>
    //       </tr>
    //       {/* Add other rows as needed */}
    //     </tbody>
    //   </table>
    // ),
  };

  return (
    <div className="mx-6 lg:mx-20 lg:my-10">
      {/* Tabs */}
      <div className="flex space-x-4 border-b  border-gray-200 mb-6">
        {[
          "Term Deposit",
          "More than 2 Crore",
          "Less than 2 Crore",
          // "Rate of Interest",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`py-3 px-1 lg:px-4 lg:py-2 text-xs lg:text-base ${
              activeTab === tab
                ? "border-b-2 border-green-900 text-green-900 font-semibold "
                : "text-gray-600 hover:text-green-900"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Table Content */}
      <div className=" lg:text-base text-xs lg:pb-0 pb-10">
        {tables[activeTab]}
      </div>
    </div>
  );
}
