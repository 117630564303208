import React, { useState } from "react";

export default function CheckRatesLoans() {
  const [activeTab, setActiveTab] = useState("Micro Loans");

  const tables = {
    "Micro Loans": (
      <table className="border-collapse border border-gray-300 w-full lg:w-[65vw] text-left">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Scheme
            </th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Interest Rate
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Loans to SHG (NRLM) upto 3.00 Lakhs
            </td>
            <td className="border border-gray-300 px-4 py-2">7.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Loans to SHG (NRLM) upto 3.00 - 5.00 Lakhs
            </td>
            <td className="border border-gray-300 px-4 py-2">8.75%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Loans to SHG (NRLM) upto 5.00 Lakhs and above
            </td>
            <td className="border border-gray-300 px-4 py-2">12.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              SWAROJGAR credit card
            </td>
            <td className="border border-gray-300 px-4 py-2">12.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              General credit card
            </td>
            <td className="border border-gray-300 px-4 py-2">14.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Cash credit</td>
            <td className="border border-gray-300 px-4 py-2">14.00%</td>
          </tr>
        </tbody>
      </table>
    ),
    "Farm Loans": (
      <table className="border-collapse border border-gray-300 w-full lg:w-[65vw] text-left">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Scheme
            </th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Interest Rate
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              KISHAN credit card
            </td>
            <td className="border border-gray-300 px-4 py-2">7.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              MT-Agri and Allied Loans
            </td>
            <td className="border border-gray-300 px-4 py-2">12.00%</td>
          </tr>

          <tr>
            <td className="border border-gray-300 px-4 py-2">
              MT Non Agri Loans
            </td>
            <td className="border border-gray-300 px-4 py-2">13.00%</td>
          </tr>
        </tbody>
      </table>
    ),
    "Non-Farm Loans": (
      <table className="border-collapse border border-gray-300 w-full lg:w-[65vw] text-left">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Scheme
            </th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">
              Interest Rate
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Salary loan (Consumer with salary account)
            </td>
            <td className="border border-gray-300 px-4 py-2">12.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Salary Loan (General)
            </td>
            <td className="border border-gray-300 px-4 py-2">13.00%</td>
          </tr>

          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Housing Loan (Salary account)
            </td>
            <td className="border border-gray-300 px-4 py-2">9.50%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Housing Loan (General)
            </td>
            <td className="border border-gray-300 px-4 py-2">9.95%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Vehicle Loan</td>
            <td className="border border-gray-300 px-4 py-2">12.00%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Two wheeler Loan
            </td>
            <td className="border border-gray-300 px-4 py-2">12.00%</td>
          </tr>
        </tbody>
      </table>
    ),
  };

  return (
    <div className="mx-6 lg:mx-20 my-10">
      {/* Tabs */}
      <div className="flex space-x-4 border-b  border-gray-200 mb-6">
        {["Micro Loans", "Farm Loans", "Non-Farm Loans"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`py-3 px-1 lg:px-4 lg:py-2 text-xs lg:text-base ${
              activeTab === tab
                ? "border-b-2 border-green-900 text-green-900 font-semibold"
                : "text-gray-600 hover:text-green-900"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Table Content */}
      <div className="lg:text-base text-xs lg:pb-0 pb-10">
        {tables[activeTab]}
      </div>
    </div>
  );
}
