import React from "react";

export default function CheckRatesAccounts() {
  return (
    <>
      <div className="mt-4 mx-6 lg:mx-20 ">
        {/* Sub-tabs */}
        <div className="mt-6">
          <button className="px-4 py-2 border-b border-green-900 text-green-900  focus:outline-none font-semibold text-sm lg:text-base">
            Savings Account
          </button>
        </div>

        {/* Table */}
        <div className="my-6  text-sm lg:text-base overflow-auto ">
          <table className="border-collapse border border-gray-300 w-full lg:w-[65vw] text-left   ">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2 bg-gray-200  text-sm lg:text-base">
                  Scheme
                </th>
                <th className="border border-gray-300 px-4 py-2 bg-gray-200 text-sm lg:text-base">
                  Interest Rate
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-300 px-4 py-2">
                  Individual Account
                </td>
                <td className="border border-gray-300 px-4 py-2">4 % *</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">
                  Government/Institution/Society/Group Account
                </td>
                <td className="border border-gray-300 px-4 py-2">4 % *</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
