import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import Heading from "../../Reuseable-Components/font/Heading";

import NationalAwardWinner1 from "../../Assets/NationalAwardWinner1.jpeg";
import NationalAwardWinner2 from "../../Assets/NationalAwardWinner2.jpeg";
import NationalAwardWinner3 from "../../Assets/NationalAwardWinner3.jpeg";

export default function NationalAwardWinner() {
  const [showConfetti, setShowConfetti] = useState(true);
  const [modalImage, setModalImage] = useState(null);

  // Stop confetti after a few seconds
  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000); // Confetti runs for 5 seconds
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <section className="">
      {showConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      <div className="pt-6 md:pt-10">
        <Heading classes="md:mb-6">National Award Winner</Heading>
      </div>
      <div className="py-10 w-[90%] mx-auto">
        <div className="grid md:grid-cols-2 gap-10">
          <div className="flex flex-col gap-y-10">
            <img
              className=" object-cover cursor-pointer "
              src={NationalAwardWinner2}
              alt="NationalAwardWinner2"
              onClick={() => openModal(NationalAwardWinner2)}
            />
            <img
              className=" object-cover cursor-pointer"
              src={NationalAwardWinner1}
              alt="NationalAwardWinner1"
              onClick={() => openModal(NationalAwardWinner1)}
            />
          </div>

          <div>
            <div className="flex flex-col">
              <img
                className="h-[40vh] md:h-[65vh] lg:h-[108vh] cursor-pointer max-sm:w-[50vw] max-sm:mx-auto md:pr-10 lg:pr-14"
                src={NationalAwardWinner3}
                alt="NationalAwardWinner1"
                onClick={() => openModal(NationalAwardWinner3)}
              />
              <p className=" italic text-xs md:text-sm pt-3 text-center md:text-start lg:text-center">
                With ICA president Mr. Ariel Guarko
              </p>
            </div>
          </div>
        </div>

        <div className="mt-10 text-sm md:text-base text-justify">
          Sikkim State Cooperative Bank Ltd. (SISCO Bank) Wins Third Prize for
          'Overall Best Performance' at National Level. New Delhi, November 26,
          2024: Sikkim State Cooperative Bank has been recognized with the
          prestigious third prize for 'Overall Best Performance' under the II
          Tier Structure for the year 2022-23 at the All India Level. The award,
          conferred by the National Federation of State Cooperative Banks
          (NAFSCOB), was presented to Smt. Pema Chenzom, Managing Director of
          the bank, by Honourable Minister Shri Amit Shaji at a ceremony held at
          Bharat Mandapam, Pragati Maidan, New Delhi, on Tuesday. The
          recognition underscores the bank's consistent efforts in improving its
          services, financial stability, and contribution to the cooperative
          banking sector. This achievement highlights the bank's pivotal role in
          promoting inclusive growth and financial inclusion within the region.
        </div>
      </div>

      {/* Modal for full-screen image */}
      {modalImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <img
            className="max-w-full max-h-full px-6"
            src={modalImage}
            alt="Modal View"
          />
          <button
            className="absolute top-5 right-5 text-white text-3xl font-bold"
            onClick={closeModal}
          >
            ×
          </button>
        </div>
      )}
    </section>
  );
}
