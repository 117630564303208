/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useHref } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import GenericModal from "../modals/GenericModal";
import { MdCancel } from "react-icons/md";

import creditBonanzaVideo3 from "../../Assets/Credit_Bonaza - Cropped_lg.mp4";
const GET_NOTICE = "api/notice-circulars-homepages?populate=*";

export default function NoticeCircularsButton({
  setVideoModalIsOpen,
  setShowHomeCarousel,
  videoModalIsOpen,
}) {
  const { data, loading, error } = useAxios(GET_NOTICE);
  const [defaultTab, setDefalutTab] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  // console.log("Noticedata", data);

  const defaulttab = "bg-yellow-700";
  const nondefaulttab = "bg-yellow-700";
  return (
    <>
      <div className="fixed   top-[35%] md:top-1/2 right-0 z-50 ">
        <div className="flex flex-col gap-2">
          <div className="cursor-pointer">
            <Link to="/national-award-winner">
              <div className="relative float-right px-3 py-1.5 text-white font-sans rounded bg-purple-700 hover:bg-purple-600  items text-sm 2xl:text-base">
                <div className="absolute top-2">
                  <span class="relative flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-75 right-4 top-0.5"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-lime-500 right-4 top-0.5"></span>
                  </span>
                </div>
                <span className="font-semibold">National Award Winner</span>
              </div>
            </Link>
          </div>
          <div className="cursor-pointer">
            <Link to="/careers">
              <div className="relative float-right px-3 py-1.5 text-white font-sans rounded bg-blue-700 hover:bg-blue-600  items text-sm 2xl:text-base">
                <div className="absolute top-2">
                  <span class="relative flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-500 opacity-75 right-4 top-0.5"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-orange-500 right-4 top-0.5"></span>
                  </span>
                </div>
                <span className="font-semibold">Career Notices</span>
              </div>
            </Link>
          </div>

          {/* <div
            className="cursor-pointer"
            onClick={() => {
              setVideoModalIsOpen(true);
              setShowHomeCarousel(false);
            }}
          >
            <div className="relative float-right px-3 py-1.5 text-white font-sans rounded bg-red-700 hover:bg-red-600  items text-sm 2xl:text-base">
              <div className="absolute top-2">
                <span class="relative flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75 right-4 top-0.5"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-300 right-4 top-0.5"></span>
                </span>
              </div>
              <span className="font-semibold">Credit Bonanza Month</span>
            </div>
          </div> */}

          {/* <div>
          <a
            className="relative float-right px-3 py-1.5 text-white font-sans rounded bg-[#A16207] hover:bg-[#be7106]    items text-sm 2xl:text-base"
            href="https://files.siscobank.com/Printfile_Donation_poster_37066506.pdf"
            target="_blank"
          >
            <div className="absolute top-2"></div>
            <span className="font-semibold">Donate to CM's Relief Fund</span>
          </a>
        </div> */}
          {data?.map((noticedata) => (
            <div>
              <button
                type="button"
                className={`float-right px-3 py-1.5 text-white font-sans rounded  hover:bg-[#be7106]  items text-sm 2xl:text-base  
              ${defaultTab === noticedata?.id ? defaulttab : nondefaulttab} `}
              >
                <a
                  href={
                    noticedata?.attributes?.notice_circular_pdf?.data[0]
                      ?.attributes?.url
                  }
                  target="_blank"
                >
                  {noticedata?.attributes?.title}
                </a>
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="">
        <GenericModal
          open={videoModalIsOpen}
          setOpen={setVideoModalIsOpen}
          title={" "}
          isLarge={true}
          isAdd={false}
        >
          <div className="flex justify-center">
            <div className="mx-0 md:mx-10 lg:mx-0 flex justify-center  relative bg-white">
              <video playsInline autoPlay loop muted className="scale-110">
                <source src={creditBonanzaVideo3} type="video/mp4" />
              </video>

              <div
                className=" text-[#16983E] absolute top-1 md:-top-6 lg:top-0 right-1 md:right-0 cursor-pointer text-xl lg:text-3xl "
                onClick={() => {
                  setVideoModalIsOpen(false);
                  setShowHomeCarousel(true);
                }}
              >
                <MdCancel color="red" />
              </div>
            </div>
          </div>
        </GenericModal>
      </div>
    </>
  );
}
