import React, { useState } from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import Accounts from "./CheckRatesAccounts";
import Deposits from "../Prdoucts/Deposits";
import CheckRatesAccounts from "./CheckRatesAccounts";
import CheckRatesDeposit from "./CheckRatesDeposit";
import CheckRatesLoans from "./CheckRatesLoans";

export default function CheckRatesConfig() {
  const [activeTab, setActiveTab] = useState(1);

  const activetab = "font-body bg-[#0E381D] text-white ";
  const nonactivetab = "font-body text-gray-600 font-semibold";

  let checkrates = [
    {
      checkrateid: 1,
      checkratename: "Account",
    },
    {
      checkrateid: 2,
      checkratename: "Deposit",
    },
    {
      checkrateid: 3,
      checkratename: "Loans",
    },
  ];

  console.log("activeTab", activeTab);

  const changeTab = (id) => {
    setActiveTab(id);
  };
  return (
    <>
      <div className=" my-10 container ">
        <Heading classes={`lg:mb-10`}>Check Rates</Heading>

        <div className="flex flex-row gap-6 my-6 mx-6 lg:mx-20  ">
          {checkrates.map((checkrates) => (
            <div
              className={` font-sans px-3 py-1 lg:px-6 lg:py-2 rounded-3xl cursor-pointer shadow-lg text-sm 2xl:text-lg ${
                checkrates.checkrateid === activeTab ? activetab : nonactivetab
              }`}
              onClick={() => changeTab(checkrates.checkrateid)}
            >
              {checkrates.checkratename}
            </div>
          ))}
        </div>
      </div>

      {activeTab === 1 && <CheckRatesAccounts />}
      {activeTab === 2 && <CheckRatesDeposit />}
      {activeTab === 3 && <CheckRatesLoans />}
    </>
  );
}
